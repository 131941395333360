import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TeamCard from "./TeamCard";

function LeadershipTeam(props) {
  const data = useStaticQuery(graphql`
    query {
      desktop1: file(relativePath: { eq: "images/About Page/zach.png" }) {
        childImageSharp {
          fixed(height: 227, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop2: file(relativePath: { eq: "images/About Page/ben.png" }) {
        childImageSharp {
          fixed(height: 227, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop3: file(relativePath: { eq: "images/About Page/tyler.jpg" }) {
        childImageSharp {
          fixed(height: 227, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop4: file(relativePath: { eq: "images/About Page/Katey.png" }) {
        childImageSharp {
          fixed(height: 227, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop5: file(relativePath: { eq: "images/About Page/ellen.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop6: file(relativePath: { eq: "images/About Page/lori.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop7: file(relativePath: { eq: "images/About Page/stephanie.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop8: file(relativePath: { eq: "images/About Page/maggie.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop9: file(relativePath: { eq: "images/About Page/kayla.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop10: file(relativePath: { eq: "images/About Page/corey.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop12: file(relativePath: { eq: "images/About Page/hannah.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop13: file(relativePath: { eq: "images/About Page/raleigh.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop14: file(relativePath: { eq: "images/About Page/michael.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop16: file(relativePath: { eq: "images/About Page/stacie.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop17: file(relativePath: { eq: "images/About Page/jeff.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop18: file(relativePath: { eq: "images/About Page/rachael.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop19: file(relativePath: { eq: "images/About Page/caleb.jpg" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop20: file(relativePath: { eq: "images/About Page/davis.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop21: file(relativePath: { eq: "images/About Page/max.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop22: file(relativePath: { eq: "images/About Page/jason.png" }) {
        childImageSharp {
          fixed(height: 227) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const memberImage1 = data.desktop1.childImageSharp.fixed;
  const memberImage2 = data.desktop2.childImageSharp.fixed;
  const memberImage3 = data.desktop3.childImageSharp.fixed;
  const memberImage4 = data.desktop4.childImageSharp.fixed;
  const memberImage5 = data.desktop5.childImageSharp.fixed;
  const memberImage6 = data.desktop6.childImageSharp.fixed;
  const memberImage7 = data.desktop7.childImageSharp.fixed;
  const memberImage8 = data.desktop8.childImageSharp.fixed;
  const memberKayla = data.desktop9.childImageSharp.fixed;
  const memberCorey = data.desktop10.childImageSharp.fixed;
  const memberHannah = data.desktop12.childImageSharp.fixed;
  const memberRaleigh = data.desktop13.childImageSharp.fixed;
  const memberMichael = data.desktop14.childImageSharp.fixed;
  const memberStacie = data.desktop16.childImageSharp.fixed;
  const memberJeff = data.desktop17.childImageSharp.fixed;
  const memberRachael = data.desktop18.childImageSharp.fixed;
  const memberCaleb = data.desktop19.childImageSharp.fixed;
  const memberDavis = data.desktop20.childImageSharp.fixed;
  const memberMax = data.desktop21.childImageSharp.fixed;
  const memberJason = data.desktop22.childImageSharp.fixed;

  let teamData = [
    {
      image: memberImage1,
      name: "Zach Korzyk",
      title: "Founder/CEO",
      description: `Zach taught high school math at Manhattan Village Academy for 13 years, during which time he was awarded four fellowships by Math for America, named three times a Master Teacher. Zach's teaching career culminated in his winning the (Zach) Sloan Award for Excellence in Teaching. In early 2020, Zach decided to focus full time on DeltaMath, allowing him to create a bevy of new features to support teachers and students coping with distance learning. “I saw the impact that DeltaMath had on students' learning first hand. I'm very excited to see so many teachers and students now reaping the benefits.”`,
    },
    {
      image: memberImage2,
      name: "Ben Peled",
      title: "VP/Director of Curriculum",
      description: `Ben spent his twenties teaching English abroad. He was drawn to teaching math out of the beauty of helping students understand the most important question: why. As a Math for America Fellow and Master Teacher, he spent seven years at Millennium High School in Manhattan, where he was among the first teachers to adopt DeltaMath for the classroom. In 2014 he joined Metamorphosis Teaching Learning Communities, working as a professional developer, coach, and consultant. He also taught math methods courses at Fordham and NYU-Steinhardt. He’s passionate about improving student access to mathematical concepts and understanding. He joined DeltaMath full time in 2020.`,
    },
    {
      image: memberImage4,
      name: "Katey Phillips",
      title: "Math Content Programmer",
      description:
        "Katey spent her undergraduate years studying Great Books at St. John’s College, where she fell in love with the works of such mathematicians as Euclid, Lobachevsky, and Dedekind (the namesake of one of her late pet rats). She furthered her studies as a postbaccalaureate fellow at the Center for Women in Mathematics at Smith College. During her first semester of graduate school in California, she found herself overwhelmingly invested in her teaching duties, fascinated with how math is taught and learned. Upon being awarded a Math for America fellowship, she moved to New York City where she taught high school math at Academy for Young Writers in Brooklyn and Manhattan Village Academy. After transitioning into software engineering, she joined DeltaMath full time in 2020.",
    },
    {
      image: memberImage6,
      name: "Lori Bodner",
      title: "Content Designer",
      description:
        "Lori has taught high school math in NYC public schools for the past 14 years. She is a Desmos Fellow, a three-time Math for America Master Teacher, and a recipient of the (Lori) Sloan Award for Teaching in 2019. Lori has been using DeltaMath in her classroom since its creation, and as a contributor for DeltaMath, she runs professional developments, creates videos, and is developing the BC Calculus content for the site.",
    },
    {
      image: memberImage5,
      name: "Ellen Wuu",
      title: "Program Associate",
      description: `Ellen has 9 years of experience working in math education. Before joining DeltaMath, she worked as a middle school math teacher at Booker T. Washington Middle School (M.S. 54) where she taught 6th and 8th-grade math. She’s particularly passionate about creating learning experiences that grow student confidence and motivation. She fell in love with DeltaMath after using it in her classroom during distance learning. “I love how DeltaMath allows teachers to customize materials to fit their curricula while providing them with the necessary tools and data to track student learning.”`,
    },
    {
      image: memberImage7,
      name: "Stephanie Ling",
      title: "Contributing Developer",
      description:
        "Stephanie taught middle school math for 14 years. Most recently, she taught 7th grade math at Jane Lathrop Stanford Middle School in Palo Alto, California. She is passionate about making mathematics accessible to students through visualization and problem solving tasks, inspiring students to tackle problems with various approaches. Stephanie loves exploring and incorporating different technologies that enrich the development of math concepts and spark students’ curiosity. Outside of her public school career, Stephanie has also taught extracurriculars in robotics engineering and Scratch programming to K-5 students. She joined DeltaMath in the summer of 2020 as a content contributor.",
    },
    {
      image: memberImage8,
      name: "Maggie Weinreb",
      title: "Project Manager",
      description:
        "Maggie has been a math educator for seven years, previously teaching middle school and high school math in NYC as a Math for America Master Teacher. During remote learning, Maggie embraced a variety of technological tools to support her students, which led to an interest in educational technology. Maggie loves the intersection of math education and tech and was thrilled to join the team in August 2021. She holds a B.S. in Mathematics & Psychology from Union College and an M.A. in Math Education from CCNY, during which she wrote her Master’s thesis on – what else – DeltaMath.",
    },
    {
      image: memberImage3,
      name: "Tyler Kremberg",
      title: "VP Engineering",
      description:
        "Tyler's first profound experience with mathematics was when his 9th grade math teacher invited him to join pre-calculus class to learn proof by induction. A fan of logic puzzles, Rubik’s cubes and nonograms, Tyler has been building and leading engineering teams in a variety of industries for over 20 years.  He graduated with a B.A in Mathematics and Computer Science from Middlebury College.",
    },
    {
      image: memberCorey,
      name: "Corey Connor",
      title: "Senior Software Engineer",
      description:
        "Getting his start in web technology at an early age while hustling myspace customizations in middle school, Corey went on to enter his undergraduate studies with a plan to study electrical engineering but quickly discovered a true passion for the abstract, graduating from Central Connecticut State University with a B.S. in Physics & Mathematics. Following graduation he entered his professional and spent seven years developing enterprise software before joining DeltaMath in April of 2022.",
    },
    {
      image: memberKayla,
      name: "Kayla Molina",
      title: "Program Associate",
      description:
        'Kayla has been a high school math educator for 12 years in the DFW area. She has taught 9th through 12th grade with all levels of math students from remedial to advanced learners. Kayla is passionate about growing student confidence through differentiated instruction in the classroom. "I love how much control DeltaMath gives teachers in creating instruction that fits the needs of their students."',
    },
    {
      image: memberHannah,
      name: "Hannah McDowell",
      title: "Senior Software Engineer",
      description:
        "Hannah taught high school math and computer science in Los Angeles Unified School District for 12 years. During that time, she was fortunate enough to receive three fellowships from Math for America Los Angeles and she earned her National Board Certification. Thankfully, teachers at MfA introduced her to DeltaMath and she was hooked! After making a career transition to full-time computer science, she is thrilled to join the DeltaMath team as a software engineer. She loves creating learning experiences that help students make sense of math.",
    },
    {
      image: memberRaleigh,
      name: "Raleigh Mumford",
      title: "Senior Software Engineer",
      description:
        "Raleigh Started he professional development career after graduating from Grand Valley State University with a Bachelors in Computer Science and a minor in Electrical Engineering. After graduation Raleigh has had an interesting career writing software for many different industries including FinTech, EdTech, medical applications and writing internal tools for manufacturing companies. Raleigh is back working on educational apps, joining DeltaMath in 2023.",
    },
    {
      image: memberMichael,
      name: "Michael Baum",
      title: "Software Engineer",
      description:
        "Prior to joining DeltaMath in 2023, Michael taught high school math for the NYC Department of Education and in Westchester, NY. While teaching, he primarily taught a combined precalculus/algebra II course and AP Statistics. He spent most summers taking self-directed computer science courses for professional development before jumping into a more comprehensive software engineering bootcamp. Though not currently in the classroom, he loves that he can work on a platform which supports teachers in helping their students feel more confident in their mathematics practices.",
    },
    {
      image: memberStacie,
      name: "Stacie Alpizar",
      title: "Lead Product Designer",
      description:
        "Stacie is a Product Designer and Design leader with a strong focus on the field of EdTech, learning, and tackling intricate design challenges. Her design philosophy revolves around a user-centric approach, grounded in a thorough understanding of user needs. She places great importance on observing and actively listening to users, enabling her to develop innovative solutions that align with both user requirements and business objectives. Stacie joined DeltaMath in 2023, excited to continue designing the finest digital experiences for teachers and students.",
    },
    {
      image: memberJeff,
      name: "Jeff Dodds",
      title: "Curriculum Specialist",
      description:
        "Jeff was a teacher at Kalamazoo Central High School for ten years, where he taught Algebra I/II, Geometry, Intro Statistics & Probability, and AP Statistics. He spent his summers doing curriculum work for his district and contract work in the edtech space, which led to a transition out of the classroom. He worked on the content team at Khan Academy for seven years before joining DeltaMath in 2024. He loves making rigorous learning resources that are engaging, accessible, and standards-aligned.",
    },
    {
      image: memberRachael,
      name: "Rachael Donati",
      title: "Program Associate",
      description:
        "Rachael taught middle school math in the Baltimore area for 7 years. She was a recipient of the MCTM Outstanding Teacher of the Year Award for the state of Maryland in 2017. She discovered DeltaMath during virtual learning, and happily supported other teachers in her school district to successfully implement the technology in their classrooms. She previously worked as a Content & Curriculum Specialist at GoGuardian, where she designed K-12 math content. Rachael is passionate about the innovative and thoughtful use of technology to enhance student curiosity and learning, and she was thrilled to join DeltaMath in 2024.",
    },
    {
      image: memberCaleb,
      name: "Caleb Rash",
      title: "Senior Software Engineer",
      description: "Caleb had a healthy disdain for math until he was required to take Physics in college. Suddenly all of the abstract concepts he had encountered throughout his youth had a purpose in a tangible world. (And he wished he'd paid more attention in class before then.) Quite coincidentally, Caleb has made a career in EdTech, helping to ensure that people who want to learn math and science have a better experience than he did. Most recently, he was a founding engineer at an EdTech startup where he helped lead engineering decisions for over 12 years.",
    },
    {
      image: memberDavis,
      name: "Davis Mercier",
      title: "Math Content Programmer",
      description: "Davis spent his undergraduate years studying mathematics and education at the University of Maine.  After graduating, he taught high school math for 11 years in various schools across New England.  During that time, Davis discovered DeltaMath and began using it extensively as part of his instruction.  His interaction with DeltaMath was a significant part of his inspiration to pursue becoming a full-time computer programmer.  He began studying programming, in addition to teaching, and in 2022 Davis became a full time application developer for IDEXX Laboratories.  He enjoyed the challenges and opportunities to learn in this role, but still desired to be a part of mathematics education through his work as a programmer.  He was thrilled to join DeltaMath in 2024 and pursue both his passions of math education and computer programming.",
    },
    {
      image: memberMax,
      name: "Max Rosenwasser",
      title: "Math Content Programmer",
      description: "Max's journey in education began with a strong foundation in mathematics, fostered by a Montessori education that encouraged exploration and self-paced learning. After graduating from Brown University with a degree in Computer Science and Classics (Latin), he joined Teach for America and taught high school math and computer science in Los Angeles for seven years. In 2024, Max joined DeltaMath, driven by his passion for creating accessible resources that empower students to build their mathematical skills, deepen their conceptual understanding, and grow into independent learners.",
    },    
    {
      image: memberJason,
      name: "Jason Oscar",
      title: "Senior Software Engineer",
      description: "Jason started software engineering after college and has spent his career working to write software and build systems that leave a positive impact on their users and the world. After working in healthcare technology for 7 years he's thrilled to make the jump to education, joining DeltaMath in 2024. He's eager to help students and teachers have a great experience in math class. When he's not behind the keyboard you can find him bouldering or biking around Chicago.",
    }
  ];
  return (
    <div className="container about__leadership">
      <h1 className="about__leadership-header">DeltaMath Team</h1>
      <div className="about__leadership-grid">
        {teamData.map((teamMember, idx) => {
          return (
            teamMember && (
              <div
                className="about__leadership-grid-teamCard-container"
                key={idx}
              >
                <TeamCard cardInfo={teamMember} location={props.location} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default LeadershipTeam;
