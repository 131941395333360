import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

function Hero() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "images/About Page/Rectangle 3.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    // <div className="about__hero">
    <BackgroundImage
      Tag="section"
      className="about__hero"
      fluid={imageData}
      backgroundColor={`#303d4e`}
    >
      <div className="container about__hero-shadow">
        <div>
          <h1 className="about__hero-header index__hero-header-nowrap">
            Built by{" "}
            <span className="about__hero-underline-parent">
              teachers<span className="about__hero-underline-child"></span>
            </span>
            .
          </h1>
          <div className="about__hero-sub-header-container">
            <h3 className="about__hero-sub-header">
              DeltaMath comes straight from the source.
            </h3>
            <h3 className="about__hero-sub-header">
              Proven learning that you can trust.
            </h3>
          </div>
        </div>
      </div>
    </BackgroundImage>
    // </div>
  );
}

export default Hero;
