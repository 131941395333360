import React from "react";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import Layout from "../components/Layout/Layout";
import Hero from "../components/About/Hero";
import AboutSection from "../components/About/AboutSection";
import LeadershipTeam from "../components/About/LeadershipTeam";
import TeamCarousel from "../components/About/TeamCarousel";

import "../styles/pages/about.scss";

export default function About(props) {
  // const [visible, enable, disable] = useToggle(false);
  // this is just to say whatevs

  return (
    <Layout>
      <Hero />
      <AboutSection />
      <LeadershipTeam location={props.location} />
      <TeamCarousel />
    </Layout>
  );
}
