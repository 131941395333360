import React from "react";
import { Card, CardTitle, CardHeader } from "@react-md/card";
import { Typography } from "@react-md/typography";
import Quote from "../../assets/images/About Page/quote.png";

function CarousalCard2(props) {
  return (
    <div>
      <Card className="carousalCard__card2">
        {/* <CardContent> */}
        <div className="carousalCard__quote2">
          {/* <FaQuoteLeft /> */}

          <img src={Quote} alt="Quote" />
        </div>
        <Typography className="carousalCard__text2">{props.cardInfo.text}</Typography>
        {/* </CardContent> */}

        <CardHeader>
          <CardTitle className="carouselCard__personDetails">{`${props.cardInfo.name}, ${props.cardInfo.role}`}</CardTitle>
        </CardHeader>
      </Card>
    </div>
  );
}

export default CarousalCard2;
