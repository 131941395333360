import React from "react";
import Img from "gatsby-image";
import { Card, CardHeader, CardTitle, CardSubtitle } from "@react-md/card";
import { MediaContainer } from "react-md";

import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { Typography } from "@react-md/typography";
import { useToggle } from "@react-md/utils";

import "../../styles/components/about/TeamCard.scss";

const replaceLinks = (description) => {
  let finalDescription = description
    .replace(
      "(Zach) Sloan Award for Excellence in Teaching",
      '<a href="https://www.fcny.org/fcny/core/sae/2019_winners/#zach-korzyk" target="_blank">Sloan Award for Excellence in Teaching</a>'
    )
    .replace(
      "(Lori) Sloan Award for Teaching",
      '<a href="https://www.fcny.org/fcny/core/sae/2019_winners/#loribodner" target="_blank">Sloan Award for Teaching</a>'
    )
    .replace(
      "Math for America",
      '<a href="https://www.mathforamerica.org" target="_blank">Math for America</a>'
    );
  return <span dangerouslySetInnerHTML={{ __html: finalDescription }} />;
};

const stripSlashes = (path) => {
  return path.split("/").join("");
};

function TeamCard(props) {
  const [visible, enable, disable] = useToggle(false);

  React.useEffect(() => {
    const overlay = document.getElementById("simple-dialog-overlay");
    if (
      overlay !== undefined &&
      overlay !== null &&
      stripSlashes(props.location.pathname) === "about"
    ) {
      overlay.style.backgroundColor = "rgba(0, 0, 0, 0.25)";
    }
  }, [props.location.pathname, visible]);

  return (
    <div>
      <Card className="teamCard" id="simple-dialog-toggle" onClick={enable}>
        <MediaContainer fullWidth className="dm-team-member-img">
          <Img fixed={props.cardInfo.image} alt={`DeltaMath Team Member: ${props.cardInfo.name}`} />
        </MediaContainer>
        <CardHeader className="teamCard-text">
          <CardTitle className="teamCard-text-name">
            {props.cardInfo.name}
          </CardTitle>
          <CardSubtitle className="teamCard-text-title">
            {props.cardInfo.title}
          </CardSubtitle>
        </CardHeader>
      </Card>
{
      <Dialog
        id="about-leadership-dialog"
        visible={visible}
        onRequestClose={disable}
        aria-labelledby="dialog-title"
        className="teamCardDialog"
      >
        <span className="sr-only" id="dialog-title">
          Team member description dialog
        </span>
        <DialogFooter>
          <VscChromeClose
            className="dialog-close1"
            id="dialog-close"
            onClick={disable}
          />
        </DialogFooter>
        <DialogContent className="about__leadership-dialogContent">
          <Card
            className="modalTeamCard"
            id="simple-dialog-toggle"
            onClick={enable}
          >
            <MediaContainer fullWidth>
              <Img
                fixed={props.cardInfo.image}
                style={{ width: "100%" }}
                alt="team member image"
              />
            </MediaContainer>
            <CardHeader className="teamCard-text">
              <CardTitle className="teamCard-text-name">
                {props.cardInfo.name}
              </CardTitle>
              <CardSubtitle className="teamCard-text-title">
                {props.cardInfo.title}
              </CardSubtitle>
            </CardHeader>
          </Card>
          <Typography className="modalDescription" margin="none">
            {replaceLinks(props.cardInfo.description)}
          </Typography>
        </DialogContent>
      </Dialog> }
    </div>
  );
}

export default TeamCard;
