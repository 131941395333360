import React from "react";
import Slider from "react-slick";

import CarousalCard2 from "./CarouselCard2";

// CUSTOM ARROWS FOR REACT SLICK CAROUSAL
const RightArrow = ({ className, style, onClick }) => (
  <div
    className="right-arrow"
    style={{
      ...style,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "10px",
      padding: "20px",
      cursor: "pointer",
      backgroundColor: "transparent",
      zIndex: "3",
    }}
    onClick={onClick}
  >
    <div
      style={{
        width: "30px",
        height: "30px",
        transform: "translateX(-50%) rotate(-135deg)",
        boxShadow: "-2px 2px 0 #ffffff",
        opacity: "0.8",
      }}
      onClick={onClick}
    ></div>
  </div>
);

const LeftArrow = ({ className, style, onClick }) => (
  <div
    className="left-arrow"
    style={{
      ...style,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "10px",
      padding: "20px",
      cursor: "pointer",
      backgroundColor: "transparent",
      zIndex: "3",
    }}
    onClick={onClick}
  >
    <div
      style={{
        width: "30px",
        height: "30px",
        transform: "translateX(0%) rotate(45deg)",
        boxShadow: "-2px 2px 0 #ffffff",
        opacity: "0.8",
      }}
      onClick={onClick}
    ></div>
  </div>
);

//  SETTINGS FOR REACT SLICK CAROUSAL
let settings = {
  dots: false,
  infinite: true,
  lazyLoad: "ondemand",
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "20px",
  centerMode: false,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
};

const carouselData = [
  {
    name: "Alice Riccabona",
    role: "Crozet, VA",
    text: "DeltaMath has been an invaluable tool for my math class over the past few years. The team at DeltaMath has been very receptive to requests and suggestions and is continually expanding features.",
  },
  {
    name: "CC Travers",
    role: "Warrenton, OR",
    text: `The thing for me is the KIDS LIKE IT! They are becoming more independent learners because of it. Instead of hearing “I don't get it” now I hear “I'm just watching the video so I can figure it out!”`,
  },
  {
    name: "Chris Wheat",
    role: "Poplar Bluff, MO",
    text: "I gave my first test today, and it was flawless. I was able to block students from seeing results, then I went through each test with students before they left that hour. Kudos!!!",
  },
  {
    name: "Cody Beyers",
    role: "Grain Valley, MO",
    text: `Quote from one of my students today: “DeltaMath is the only reason I’m passing this class.”`,
  },
  {
    name: "Linda Bensyl",
    role: "Wayland, MI",
    text: `The flexibility coupled with individualized skill practice is a teacher's dream. Likewise, the ability to collaborate with others and design customized assessments is instrumental.`,
  },
  {
    name: "Matthew Braddock",
    role: "Ellicott City, MD",
    text: `I presented it to peers across my district. Many of them have moved to DeltaMath for homework and virtual tests!`,
  },
  {
    name: "Melody Hastings",
    role: "Avondale, AZ",
    text: `I have yet to find a curriculum with enough skill practice, but DeltaMath is the perfect solution! DeltaMath provides an excellent variety of problems, assignments, and helpful resources for students. They love it too!`,
  },
  {
    name: "Nathaniel Lackey",
    role: "Fairfield, IL",
    text: `DeltaMath has been a lifesaver for me as a young teacher, especially DeltaMath PLUS. Being able to create tests with my own problems while using this interface is incredible!`,
  },
  {
    name: "Paige LaBarr",
    role: "Pittsford, NY",
    text: `This site is a real time saver when it comes to creating high-quality instruction and practice for students.`,
  },
  {
    name: "Sara Pasacreta",
    role: "Redding, CT",
    text: `I love that DeltaMath gives hints, similar problems, and shows the steps to all problems helping students to understand their mistake rather than just telling them it’s incorrect.`,
  },
];

function TeamCarousel() {
  return (
    <div className="about__teamCarousel">
      <Slider className="container about__teamCarousel-carousal" {...settings}>
        {carouselData
          .sort(() => Math.random() - 0.5)
          .map((data, index) => {
            return (
              <div
                key={index + 1000}
                className="about__teamCarousel-carousal-cardContainer"
              >
                <CarousalCard2 cardInfo={data} />
              </div>
            );
          })}
      </Slider>
    </div>
  );
}

export default TeamCarousel;
