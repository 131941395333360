import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Button } from "react-md";

function AboutSection() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop1: file(relativePath: { eq: "images/About Page/Zach top.png" }) {
          childImageSharp {
            fixed(quality: 90, height: 316) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  const aboutSectionImage = data.desktop1.childImageSharp.fixed;

  return (
    <div className="container about__aboutSection">
      <div className="about__aboutSection-image-container">
        {/* <img src={infoImage} alt="teacher image" className="about__aboutSection-image" /> */}
        <Img
          fixed={aboutSectionImage}
          alt="Unlimited practice"
          className="about__aboutSection-image"
        />
      </div>
      <div className="about__aboutSection-info">
        <p className="about__aboutSection-info-description">
          Zach Korzyk created DeltaMath in 2009 when he wrote a simple program on substituting values into the Quadratic Formula. Positive student response prompted him to kick into gear and create content for the rest of the school year.
        </p>
        <p className="about__aboutSection-info-description">
          In its first full year as a functional site, DeltaMath was used by 100 teachers and 5,000 students. In the years to come, Zach worked nights, weekends, and summers adding content and features. He taught himself database management, web servers, and JavaScript. By 2019-2020, the site had grown to over 100,000 teacher-users and 4 million students.
        </p>
        <div className="about__aboutSection-info-buttonContainer">
          <Link to="/contact">
            <Button
              className="about__aboutSection-info-button"
              theme="primary"
              themeType="outline"
            >
              Contact Us
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
